<script lang="ts" setup>
import { useToast } from 'vue-toastification'
import * as yup from 'yup'

export type WidgetNewsletterVariant = 'primary' | 'accent'
export type WidgetNewsletterRequestType = 'newsletter' | 'document'

interface WidgetNewsletterProps {
  variant?: WidgetNewsletterVariant
  requestType?: WidgetNewsletterRequestType
  title?: string
  text?: string
  toastText?: string
  placeHolder?: string
  buttonTitle?: string
}

const props = withDefaults(defineProps<WidgetNewsletterProps>(), {
  variant: 'primary',
  requestType: 'newsletter',
  title: 'Chcete mít přehled o dění ve světě kryptoměn?',
  text: 'Připojte se k odběru novinek a budete pravidelně informováni o nových trendech, událostech a analýzách.',
  toastText: 'Děkujeme za odběr novinek.',
  placeHolder: 'Zadejte Váš email ...',
  buttonTitle: 'Odebírat!',
})

const toast = useToast()
const { emailValidation } = useValidations()

async function onSubmit (values: any, { resetForm }: any) {
  const { error } = await useFetch('/api/fubex-components/newsletter/subscribe', {
    method: 'POST',
    body: {
      email: values.email,
      requestType: props.requestType,
    },
  })

  if (error.value) {
    toast.error('Něco se pokazilo, zkuste to prosím později.')
    return
  }

  resetForm()
  toast.success(props.toastText)
}

const validationSchema = yup.object({
  email: emailValidation,
})
</script>

<template>
  <div
    relative fubex-rounded-xl
    lg="block px-40px py-32px"
    overflow-clip
    :class="[
      variant === 'primary' ? ' bg-primary-500 dark:bg-neutral-200' : '',
      variant === 'accent' ? ' bg-accent-500 dark:bg-neutral-200' : '',
    ]"
  >
    <VForm
      v-slot="{ meta, isSubmitting }"
      :validation-schema="validationSchema"
      flex flex-col gap-8px p-16px
      @submit="onSubmit"
    >
      <h3
        heading4 font-500 z-10
        :class="[
          variant === 'primary' ? 'text-white' : '',
          variant === 'accent' ? 'text-primary-900' : '',
        ]"
      >
        {{ title }}
      </h3>
      <p
        paragraph-md mt-8px mb-24px z-10
        :class="[
          variant === 'primary' ? 'text-white' : '',
          variant === 'accent' ? 'text-primary-900' : '',
        ]"
      >
        {{ text }}
      </p>
      <div flex gap-8px z-10>
        <FInputVee
          name="email"
          cypress-prefix="newsletter"
          type="text" :placeholder="placeHolder"
          lg="w-420px"
        />
        <FButton name="news-remove" :disabled="!meta.valid" :variant="variant === 'accent' ? 'on-accent-bg' : 'dark'" size="lg" max-h-50px :loading="isSubmitting" type="submit">
          {{ buttonTitle }}
        </FButton>
      </div>
      <img
        absolute
        top="-15% lg:-50%"
        right="-50px lg:-10%"
        h="70% lg:200%"
        :src="`${'/img/newsletter-at.svg'}`" alt="Bitbeli Newsletter"
        opacity-15 dark:opacity-5
        z-10
      >
    </VForm>
  </div>
</template>
